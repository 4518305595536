<template>
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">课程合作管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">合作方列表</a>
        </span>
            </div>
            <div class="framePage-body">
                <div class="operationControl flexdc" style="align-items: flex-start; ">
                    <div class="searchbox" style="margin-bottom:5px">

                        <div title="合作方名称" class="searchboxItem ci-full">
                            <span class="itemLabel">合作方名称:</span>
                            <el-input v-model="name" type="text" size="small" clearable placeholder="请输入合作方名称"
                            />
                        </div>
                        <el-button style="margin-left:20px" class="bgc-bv" round @click="getData()">查询</el-button>
                        <div class="btnBox" style="margin-left:20px">
                            <el-button class="bgc-bv" round @click="handleCreate('add')">新增合作方</el-button>
                        </div>
                    </div>
                </div>
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table
                                ref="multipleTable"
                                :data="tableData"
                                :height="tableHeight"
                                size="small"
                                tooltip-effect="dark"
                                style="width: 100%"
                                :header-cell-style="tableHeader"
                                stripe>
                            <el-table-column
                                    label="序号"
                                    align="center"
                                    type="index"
                                    width="100px"
                                    :index="indexMethod"/>

                            <el-table-column
                                    label="合作方名称"
                                    align="center"
                                    prop="partnerName"
                                    show-overflow-tooltip
                                    min-width="100"/>

                            <el-table-column
                                    label="合作方账号"
                                    align="center"
                                    prop="username"
                                    show-overflow-tooltip
                                    min-width="100"/>

                            <el-table-column
                                    label="添加时间"
                                    align="center"
                                    prop="createTime"
                                    show-overflow-tooltip
                                    min-width="100"/>
                            <el-table-column label="操作" align="center" width="140px" fixed="right">
                                <div slot-scope="scope" class="flexcc">
                                    <el-button
                                            type="text"
                                            size="mini"
                                            style="padding:7px 10px"
                                            @click.stop="() => doAddCourseMore(scope.row)"
                                    >导出课程</el-button>
                                    <el-button
                                            type="text"
                                            style="padding:0px 5px"
                                            size="mini"
                                            @click="seeCourseEdit(scope.row)"
                                    >导出记录
                                    </el-button>
                                </div>
                            </el-table-column>
                            <Empty slot="empty"/>
                        </el-table>
                    </div>
                </div>
                <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData"/>
                <PartnerCourseTable ref="PartnerCourseTable" />
            </div>
        </div>
        <el-dialog title="新增合作方" :visible.sync="dialogFormVisible"  :before-close="closeDocsDialog" width="35%">
            <el-form :model="groupForm" style="width:100%">
                <el-form-item label="合作方名称：" label-width="100px">
                    <div class="df">
                        <el-input v-model="groupForm.partnerName"
                                  maxlength="30"
                                  :show-word-limit="true"
                                  placeholder="请输入合作方名称"></el-input>
                    </div>
                </el-form-item>
                <el-form-item label="账号：" label-width="100px">
                    <div class="df">
                        <el-input v-model="groupForm.username" maxlength="30"
                                  :show-word-limit="true"
                                  placeholder="请输入合作方名称"></el-input>
                    </div>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer flexcc">
                <el-button @click="closeDocsDialog">取 消</el-button>
                <el-button class="bgc-bv" type="primary" @click="doOk">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import Empty from "@/components/Empty.vue";
    import PageNum from "@/components/PageNum.vue";
    import List from "@/mixins/List";
    import {resetKeepAlive} from "@/utils/common";
    import PartnerCourseTable from "@/views/Partner/PartnerCourseTable";
    export default {
        name: "Partner/partnerList",
        components: {
            Empty,
            PageNum,
            PartnerCourseTable
        },
        mixins: [List],
        data() {
            return {
                dialogFormVisible: false,
                DialogVisible: false,
                name: "",
                groupForm: {
                    partnerName: "",
                    username: ""
                },
                rules: {
                    partnerName: [
                        {required: true, message: "请输入课程名称", trigger: "blur"}
                    ],
                    username: [
                        {required: true, message: "请选择培训类型", trigger: "blur"}
                    ]
                },
            };
        },
        computed: {},
        created() {
            this.getTableHeight();
        },
        methods: {
            //取消
            closeDocsDialog(){
                this.dialogFormVisible = false;
                this.groupForm.partnerName = "";
                this.groupForm.username = "";
            },
            // 弹窗确定
            doOk() {
                let partnerName = this.groupForm.partnerName; //合作方名称
                let  username= this.groupForm.username; // 合作方账号
                if (partnerName == '') {
                    this.$message({
                        type: "warning",
                        message: "名称不能为空！"
                    });
                    return;
                }
                if (username == '') {
                    this.$message({
                        type: "warning",
                        message: "账号不能为空！"
                    });
                    return;
                }
                if (partnerName.length >30) {
                    this.$message({
                        type: "warning",
                        message: "名称长度不能大于30位！"
                    });
                    return;
                }
                if (username.length >30) {
                    this.$message({
                        type: "warning",
                        message: "账号长度不能大于30位！"
                    });
                    return;
                }
                this.$post("/biz/chinahrt/partner/insert", { partnerName: this.groupForm.partnerName,username:this.groupForm.username })
                    .then(res => {
                        if (res.status == 0) {
                            this.$message({
                                message: "添加成功!",
                                type: "success",
                                duration: 1000
                            });
                        this.closeDocsDialog();
                        this.getData();
                        }
                    })
                    .catch(() => {
                        return;
                    });
            },
            //新增合作方
            handleCreate(){
                this.dialogFormVisible = true;
            },
            // 批量添加课件
            doAddCourseMore(row) {
                this.$refs.PartnerCourseTable.showPopUp(row);
            },
            doCloseload() {
                this.centerDialogVisible = false;
            },
            getData(pageNum = 1) {
                const params = {
                    pageNum: pageNum,
                    pageSize: this.pageSize,
                    name: this.name ? this.name : "",
                };
                this.doFetch({
                    url: "/biz/chinahrt/partner/page",
                    params,
                    pageNum
                });
            },
            // 导出记录
            seeCourseEdit(row) {
                this.$router.push({
                    path: "/web/Partner/partnerRecordList",
                    query: {
                        createTime: row.createTime,
                        partnerId: row.partnerId,
                        partnerName: row.partnerName
                    }
                });
            },
            //
            getTableHeight(opDom = true, page = true) {
                let tHeight =
                    window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
                if (opDom) {
                    tHeight -= 40 + 40 + 0.675 * 16 + 1;
                }
                if (page) {
                    tHeight -= 32;
                }
                this.tableHeight = tHeight;
            }
        },
        beforeRouteLeave: resetKeepAlive,
        watch: {
            height(val) {
                this.$nextTick(() => {
                    this.$refs.multipleTable.doLayout();
                });
            },
            $route: {
                handler: function (val, oldVal) {
                    if (val.query.refrsh == true) {
                        this.getData(-1);
                    }
                    if (oldVal.query.stu == "add") {
                        (this.adminName = ""),
                            (this.compName = ""),
                            (this.fullname = ""),
                            (this.mobile = ""),
                            (this.realRoleName = ""),
                            (this.roleId = ""),
                            (this.state = ""),
                            (this.cityId = ""),
                            (this.trainTypeId = ""),
                            (this.pageNum = 1),
                            this.getData(-1);
                    }
                },
                // 深度观察监听
                deep: true
            }
        }
    };
</script>
<style lang="less">
    .addlist {
        .el-icon-plus:before {
            content: "\e6d9";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .table-switch {
        display: flex;
        align-items: center;
        justify-content: center;
        .el-switch__core {
            width: 40px !important;
        }
        .el-switch__core:after {
            background-color: #fff;
        }
        span {
            line-height: 20px;
            margin-left: 5px;
        }
    }

    .operationControl > div > div > span {
        min-width: 6rem;
    }

    .itemLabel {
        display: inline-block;
        width: 3rem;
    }
</style>
<style lang="less" scoped>
</style>
